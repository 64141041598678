import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CreateDrawer from "../CommonUi/CreateDrawer";
import AddProd from "../product/addProd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";
import BigDrawer from "../Drawer/BigDrawer";
import AddSale from "./addSale";


export default function ProductAdd({
  form,
  productList,
  productLoading,
  totalCalculator,
  invoiceData,
  subTotal,
  data,
  productsKey = "saleInvoiceProduct",
  selectProductButtonRef,
  saleType,
}) {

  const { Option } = Select;
  const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
    (state) => state.vatTax
  );

  const [openAddProd, setOpenAddProd] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(null)
  const [quantityUsed, setQuantityUsed] = useState(null)
  const [unitType, setUnitType] = useState(null)

  const [quantity, setQuantity] = useState(0);
  // if (maxQuantity != null) {
  //   if (quantity >= maxQuantity) {
  //     // toast.warning(`Maximum quantity reached: ${maxQuantity}`);
  //   }
  // }

  const buttonRef = useRef(null)

  const handleQuantityChange = (value, index) => {
    const productArray = form.getFieldValue(productsKey);
    const currentProduct = productArray[index];

    // Check if a product is selected before allowing quantity changes
    if (!currentProduct?.productId) {
      toast.error("Please select a product first");
      return;
    }

    // Validate against maxQuantity
    if (maxQuantity !== null && value > maxQuantity) {
      toast.warning(`Maximum quantity available: ${maxQuantity}`);
      value = maxQuantity;
    }

    setQuantity(value);

    // Update the form field
    form.setFieldValue([productsKey, index, 'productQuantity'], value);
    totalCalculator(index);
  };

  // const [vatPriceId, setVatPriceId] = useState(0);
  // const [gstId, setgstId] = useState();

  const handleSetInitial = ({ product, index, add, name, remove }) => {
    const productArray = form.getFieldValue(productsKey);

    // const existingProduct = productArray.find((val) => val.id === product);

    const findProduct = productList.find((pro) => pro.id === product);
    
    setMaxQuantity(findProduct?.productQuantity)
    setUnitType(findProduct?.unitType)

    // form.setFieldValue('vatPrice', findProduct?.productVatId)
    // form.setFieldValue('vatPrice', vatPriceId)

    if (findProduct.productQuantity === 0) {
      toast.warning("Product is out of stock");
    }

    setMaxQuantity(findProduct?.productQuantity);
    setQuantity(1)

    let updatingExistingProductQuanity = false;
    let newArray = productArray.map((eachProduct, productIndex) => {
      if (productIndex === index && eachProduct && !updatingExistingProductQuanity) {
        return {
          ...eachProduct,
          productQuantity: 1,
          productSalePrice: findProduct.productSalePrice,
          productMrpPrice: findProduct.productMrpPrice,
          // productVat: !!findProduct.vatPrice ? findProduct.vatPrice : 0,
          unitMeasurement: findProduct?.unitMeasurement || 0,
          discount: findProduct?.discount || 0,
          discount_type: findProduct?.discount_type || "amount",
          id: findProduct?.id,
          vatId: findProduct?.productVatId,
          vatPrice: findProduct?.productVatId ? vatTaxList?.find((v) => v?.id == findProduct?.productVatId)?.percentage : 0, //temporary value
          // vatPrice: !!findProduct?.id ? findProduct?.productVatId : 0,
          // vatPrice : findProduct?.productVat && findProduct?.productVat?.title + '@' + findProduct?.productVat?.percentage + '%',
          inclusiveGst: findProduct.inclusiveGst,
          product: findProduct,
          description: findProduct.description,
          hsn_code: findProduct.hsn_code
        };
      } else if (eachProduct?.productId === product) {
        updatingExistingProductQuanity = true;
        if (index === productIndex) {
          remove(name);
          return {};
        }
        return {
          ...eachProduct,
          productQuantity: _.get(eachProduct, "productQuantity", 0) + 1,
        }
      } else {
        return eachProduct;
      }
    });

    newArray = _.filter(newArray, each => !_.isEmpty(each));

    // const sampleVatPrice = findProduct?.productVatId

    // setVatPriceId(findProduct?.productVatId)

    // const vatFields = form.getFieldValue("vatId");

    // const vatPricePercent = findProduct?.productVat?.percentage

    // const totalVatArray =
    // 	vatFields?.map((id) => {
    // 		return vatTaxList.find((item) => id === item.id)?.percentage;
    // 	}) || [];

    form.setFieldsValue({ [productsKey]: newArray, });
    totalCalculator(index);
    add();

  };

  useEffect(() => {
    if (data) {
      const processedData = _.get(data, productsKey, [])?.map((v) => (
        {
          ...v,
          // quantity: v?.productQuantity,
          vatPrice: v?.productGST,
          description: v?.description,
          hsn_code: v?.hsn_code
        }
      ))
      form.setFieldValue(productsKey, processedData)
    }
  }, [data]);

  useEffect(() => {
    if (quantity > maxQuantity) {
      setQuantity(maxQuantity)
    }
  }, [quantity, maxQuantity])

  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
      if (selectProductButtonRef) {
        selectProductButtonRef.current = buttonRef.current
      }
    }
  }, []);

  const isDisabled = (index) => {
    const productArray = form.getFieldValue(productsKey);
    const findProduct = _.get(productArray, index);
    return !_.get(findProduct, "product.status", true);
  }

  const handleAddProdSuccess = () => {
    setOpenAddProd(false);
  }

  const handleDescriptionChange = (e, index) => {
    const { value } = e.target;
    const productArray = form.getFieldValue(productsKey);
    const updatedFields = [...productArray];
    updatedFields[index].description = value;
    form.setFieldsValue({ [`${productsKey}[${index}].description`]: value });
  };
  const handleHsncodeChange = (e, index) => {
    const { value } = e.target;
    const productArray = form.getFieldValue(productsKey);
    const updatedFields = [...productArray];
    updatedFields[index].hsn_code = value;
    form.setFieldsValue({ [`${productsKey}[${index}].hsn_code`]: value });
  };

  // console.log("product Qty", productList?.find((v) => Number(v?.id) === Number(226))?.productQuantity)

  return (
    <>
      <Row className='flex gap-5 !flex-nowrap'>
        <Col style={{ width: "30px" }}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            S.No
          </div>
        </Col>
        <Col span={4}>
          <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
            Product
          </div>
        </Col>
        {invoiceData?.show_description_pdf_invoice === true && (
          <Col span={3}>
            <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
              Description
            </div>
          </Col>
        )}
        {invoiceData?.show_Hsn_Code === true && (
          <Col style={{ width: "95px" }}>
            <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
              HSN
            </div>
          </Col>
        )}
        {
          invoiceData?.showSkuOnSale &&
          <Col span={2}>
            <div className='font-weight-bold md:text-base sm:text-sm'>
              SKU
            </div>
          </Col>
        }
        {/* <Col span={1}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            U.M.
          </div>
        </Col> */}
        <Col span={5} className="flex gap-0.5">
          <div className='w-2/5 max-lg:w-24 font-weight-bold md:text-base sm:text-sm'>
            Qty
          </div>

          {saleType === "internal" && <div className='w-3/5 max-lg:w-24 font-weight-bold md:text-base sm:text-sm'>
            Qty Used
          </div>}
          <div className='w-3/5 font-weight-bold md:text-base sm:text-sm'>
            MRP
          </div>
        </Col>
        <Col span={1}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            Dis
          </div>
        </Col>
        <Col span={2}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            Sale Price
          </div>
        </Col>
        <Col span={2}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            GST
          </div>
        </Col>
        <Col span={0.5}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            IncGST
          </div>
        </Col>
        <Col span={2}>
          <div className='lg:ml-1 font-weight-bold md:text-base sm:text-sm'>
            Total
          </div>
        </Col>
        <Col span={1}>
          <div className='md:text-base sm:text-sm'>Del</div>
        </Col>
      </Row>

      <hr style={{ backgroundColor: "black", marginTop: "0.5rem" }} />

      <Form.List
        name={productsKey}
        // initialValue={processedData}
        rules={[
          {
            required: true,
            message: "Product is required",
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <div className='max-h-[160px] overflow-y-auto overflow-x-hidden mt-1 gap-5'>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row className='flex gap-5 !flex-nowrap items-center' gutter={[5]} key={key}>
                  <Col style={{ width: "30px" }}>{index + 1}</Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, "productId"]}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Product is required",
                    //   },
                    // ]}
                    >
                      <Select
                        disabled={isDisabled(index)}
                        placeholder='Select Product'
                        showSearch
                        autoFocus={invoiceData?.showSkuOnSale ? false : true}
                        loading={productLoading}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(product) => {
                          isDisabled(product)
                          handleSetInitial({ product, index, add, remove, name });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && fields.length - 1 >= index) {
                            add(); // Add a new field when Enter is pressed
                          }
                          if (e.key == 'Delete') {
                            remove(name);
                            totalCalculator(index);
                          }
                        }}
                      >
                        {productList?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {invoiceData?.show_description_pdf_invoice === true && (
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: !fields?.length ? true : false,
                            message: "Product Description is required",
                          },
                        ]}
                      >
                        <Input
                          className=''
                          placeholder='Product Description'
                          onChange={(e) => handleDescriptionChange(e, index)}
                          disabled={isDisabled(index)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {invoiceData?.show_Hsn_Code === true && (
                    <Col style={{ width: "95px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "hsn_code"]}
                        rules={[
                          {
                            required: !fields?.length ? true : false,
                            message: "Product Hsn_code is required",
                          },
                        ]}
                      >
                        <Input
                          className=''
                          placeholder='Hsn_code'
                          onChange={(e) => handleHsncodeChange(e, index)}
                          disabled={isDisabled(index)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {
                    invoiceData?.showSkuOnSale &&
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, "productId"]}
                        rules={[
                          {
                            required: !fields?.length ? true : false,
                            message: "SKU is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select SKU'
                          showSearch
                          autoFocus={true}
                          loading={productLoading}
                          optionFilterProp='children'
                          // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase()) }
                          filterOption={(input, option) =>
                            option.children.toLowerCase() === input.toLowerCase()
                          }
                          onChange={(product) => {
                            handleSetInitial({ product, index, add, remove, name });
                          }}
                          onKeyDown={(e) => {
                            // if (e.key === "Enter" && fields.length - 1 >= index) {
                            //   add(); // Add a new field when Enter is pressed
                            // }
                            if (e.key == 'Delete') {
                              remove(name);
                              totalCalculator(index);
                            }
                          }}
                          disabled={isDisabled(index)}
                        >
                          {productList?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.sku}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  }
                  <Col span={5} className="flex gap-0.5">
                    <Form.Item className="w-2/5 max-lg:w-24"
                      {...restField}
                      name={[name, "productQuantity"]}
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "quantity is required",
                        },
                      ]}
                    >
                      {/* <InputNumber
                        style={{ width: "100%" }}
                        size={"small"}
                        placeholder='Quantity'
                        // max={subTotal[index]?.productQuantity || null}
                        // max={productList?.find((v) => v?.id === index)?.productQuantity}
                        // max={Number(form.getFieldValue(`${productsKey}[${index}].productQuantity`))}
                        max={maxQuantity}
                        value={quantity}
                        min={0}
                        onChange={(value) => {
                          setQuantity(value);
                          totalCalculator(index);
                        }}
                        // disabled={quantity === 0}
                      /> */}
                      <InputNumber
                        style={{ width: "100%" }}
                        size={"small"}
                        placeholder='Quantity'
                        max={maxQuantity}
                        value={quantity}
                        min={0}
                        onChange={(value) => handleQuantityChange(value, index)}
                      // disabled={!form.getFieldValue(`${productsKey}[${index}].productId`)} // Disable if no product selected
                      />
                    </Form.Item>
                    {saleType === "internal" && <Form.Item className="w-4/5 max-lg:w-24 ml-3"
                      {...restField}
                      name={[name, "quantityUsed"]}
                      rules={[
                        {
                          // required: !fields?.length ? true : false,
                          required: false,
                          message: "quantity is required",
                        },
                      ]}
                    >

                      <div className="flex gap-2">
                        <InputNumber
                          style={{ width: "100%" }}
                          size={"small"}
                          placeholder='Quantity'
                          // max={maxQuantity}
                          value={quantityUsed}
                          min={0}
                          onChange={(value) => handleQuantityChange(value, index)}
                        // disabled={!form.getFieldValue(`${productsKey}[${index}].productId`)} // Disable if no product selected
                        />

                        <div className={`w-4/5 my-0.5 text-sm font-weight-bold  overflow-hidden broder rounded-md  flex justify-center items-center`}>
                          {unitType}

                        </div>
                      </div>
                    </Form.Item>}

                    <div className={`w-3/5 my-0.5 mx-1 font-weight-bold md:text-base overflow-hidden broder rounded-md border-zinc-200 flex justify-center items-center ${subTotal[index]?.discount ? "bg-zinc-100" : ""}`}>
                      {subTotal[index]?.productMrpPrice?.toFixed(2)}

                    </div>
                  </Col>
                  <Col span={1}>
                    <div className={` font-weight-bold md:text-base overflow-hidden broder rounded-md border-zinc-200 ${subTotal[index]?.discount ? "bg-zinc-100" : ""}  flex justify-center items-cente p-1 px-2`}>
                      {subTotal[index]?.discount_type == "amount" ? "₹" : " "}{subTotal[index]?.discount}{subTotal[index]?.discount_type == "percentage" ? "%" : " "}
                    </div>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, "productSalePrice"]}
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "Price is required",
                        },
                      ]}
                    >
                      <InputNumber
                        size='small'
                        style={{ width: "100%" }}
                        placeholder='Sale Price'
                        onChange={() => totalCalculator(index)}
                        disabled={isDisabled(index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="!my-0.5">
                    {/* Gst section */}
                    <Form.Item
                      {...restField}
                      name={[name, "vatPrice"]}
                      // name='vatID'
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "GST is required",
                        },
                      ]}
                      initialValues={{
                        vatId: [],
                      }}
                    >
                      <Select
                        placeholder='Select GST type'
                        showArrow
                        loading={vatTaxLoading}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase() === input.toLowerCase()
                        }
                        onChange={() => totalCalculator()}
                        onKeyDown={(e) => {
                          if (e.key == 'Delete') {
                            remove(name);
                            totalCalculator(index);
                          }
                        }}
                        disabled={isDisabled(index)}
                      >
                        {vatTaxList?.map((item) => (
                          <Option key={item.id} value={item.percentage}>
                            {item.title}
                            <span className='italic'>@{item.percentage}%</span>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item
                      {...restField}
                      name={[name, "inclusiveGst"]}
                      valuePropName="checked"
                      className="w-1/3"
                    >
                      <Checkbox disabled={isDisabled(index)} name="inclusiveGst" onChange={() => totalCalculator(index)} className={"styles.InclusiveGSTItem"}></Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <div className='lg:ml-1 font-weight-bold md:text-base'>
                      {subTotal[index]?.subPrice?.toFixed(2) || 0}
                    </div>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <button
                        shape='circle'
                        className='flex justify-end items-end bg-red-600 text-white p-2 rounded-md lg:ml0.5'
                        onClick={() => {
                          remove(name);
                          totalCalculator(index);
                        }}
                      >
                        <DeleteOutlined className='' />
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              )
              )}
            </div>
            <div className="flex items-center gap-2">
              <Form.Item className="!my-2 max-sm:w-40 w-full">
                <Button
                  type='dashed'
                  ref={buttonRef}
                  onClick={() => add()}
                  block
                // icon={<PlusOutlined />}
                >
                  Select Product
                </Button>
              </Form.Item>
              <BigDrawer
                permission={"create-product"}
                title={" Product"}
                btnTitle={"Create Product"}
                openFlag={openAddProd}
                setOpenFlag={setOpenAddProd}
                children={<AddProd hideSaveAndCreate={true} onSuccess={handleAddProdSuccess} drawer={true} />}
              />
              <CreateDrawer
                permission={"create-saleInvoice"}
                title={"Sale"}
                width={92}
              >
                <AddSale />
              </CreateDrawer>
              {/* <CreateDrawer permission={"create-product"} title={"Create Product"} setOpenFlag={setOpenAddProd}>
                   <AddProd onSuccess={handleAddProdSuccess} drawer={true}/>
              </CreateDrawer> */}
            </div>
          </>
        )}
      </Form.List>
    </>
  );
}
