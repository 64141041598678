import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  list: null,
  error: "",
  loading: false,
};

export const loadDashboardData = createAsyncThunk(
  "dashboard/loadDashboardData",
  async ({ startdate, enddate }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `dashboard?startdate=${startdate}&enddate=${enddate}`
      );
      return data;
    } catch (error) {
      // Return the error message so it's captured in the rejected case
      return rejectWithValue({ message: error.message });
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    // 1) ====== builders for loadDashboardData ======
    builder.addCase(loadDashboardData.pending, (state) => {
      state.loading = true;
      state.list = null;  // Reset data
      state.error = "";   // Reset previous errors
    });
    
    // builder.addCase(loadDashboardData.pending, (state) => {
    //   state.loading = true;
    // });

    builder.addCase(loadDashboardData.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });

    builder.addCase(loadDashboardData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default dashboardSlice.reducer;
